import React from "react";
import { Box } from "@singlestore/fusion/components/layout";
import { Slot } from "@singlestore/fusion/components/slot";
import * as TooltipPrimitive from "@singlestore/fusion/components/tooltip/primitive";
import { isBrowser } from "@singlestore/fusion/utils/dom";
import { cx } from "class-variance-authority";

import "./Tooltip.scss";

export function Tooltip(
    props: React.ComponentProps<typeof TooltipPrimitive.Root>
) {
    const { delayDuration = 300, ...rest } = props;

    const [tooltipState, setTooltipState] =
        React.useState<Maybe<boolean>>(undefined);

    const touchDevice = isBrowser && "ontouchstart" in window;

    // These events will only be triggered on mobile/touchscreen devices
    // These are necessary because radix does not support tooltips in mobile devices
    const handleMouseEnter = () => {
        if (touchDevice) {
            setTooltipState(true);
        }
    };

    const handleMouseLeave = () => {
        if (touchDevice) {
            setTooltipState(undefined);
        }
    };

    return (
        <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <TooltipPrimitive.Root
                open={tooltipState}
                delayDuration={delayDuration}
                {...rest}
            />
        </span>
    );
}

Tooltip.displayName = "Tooltip";

export const TooltipProvider = TooltipPrimitive.Provider;

TooltipProvider.displayName = "TooltipProvider";

export const TooltipTrigger = React.forwardRef<
    React.ElementRef<typeof TooltipPrimitive.Trigger>,
    React.ComponentProps<typeof TooltipPrimitive.Trigger>
>((props, forwardedRef) => {
    const { className, ...rest } = props;

    return (
        <TooltipPrimitive.Trigger
            ref={forwardedRef}
            asChild
            className={cx("docs-c-tooltip__trigger", className)}
            {...rest}
        />
    );
});

TooltipTrigger.displayName = "TooltipTrigger";

export const TooltipContent = React.forwardRef<
    React.ElementRef<typeof TooltipPrimitive.Content>,
    Omit<
        TooltipPrimitive.TooltipContentImplProps &
            React.ComponentProps<typeof Box>,
        "asChild"
    >
>(
    (
        {
            className,
            children,

            // Radix props
            onEscapeKeyDown,
            onPointerDownOutside,
            side,
            sideOffset,
            align,
            alignOffset,
            avoidCollisions,
            collisionBoundary,
            collisionPadding,
            arrowPadding,
            sticky,
            hideWhenDetached,
            ...rest
        },
        forwardedRef
    ) => {
        return (
            <TooltipPrimitive.Portal>
                <TooltipPrimitive.Content
                    ref={forwardedRef}
                    asChild
                    className={cx("docs-c-tooltip__content", className)}
                    side={side}
                    sideOffset={sideOffset}
                    align={align}
                    alignOffset={alignOffset}
                    avoidCollisions={avoidCollisions}
                    collisionBoundary={collisionBoundary}
                    collisionPadding={collisionPadding}
                    arrowPadding={arrowPadding}
                    sticky={sticky}
                    hideWhenDetached={hideWhenDetached}
                    onEscapeKeyDown={onEscapeKeyDown}
                    onPointerDownOutside={onPointerDownOutside}
                >
                    <Box {...rest}>
                        {children}
                        <TooltipPrimitive.Arrow className="docs-c-tooltip__arrow" />
                    </Box>
                </TooltipPrimitive.Content>
            </TooltipPrimitive.Portal>
        );
    }
);

TooltipContent.displayName = "TooltipContent";

export function TooltipTextDefinition(
    props: React.ComponentProps<typeof Tooltip>
) {
    return <Tooltip {...props} />;
}

export const TooltipTextDefinitionTrigger = React.forwardRef<
    HTMLElement,
    React.ComponentProps<typeof TooltipPrimitive.Trigger>
>((props, forwardedRef) => {
    const { className, asChild, children: _children, ...rest } = props;

    const Comp = asChild ? Slot : "span";

    return (
        <TooltipPrimitive.Trigger
            asChild
            className={cx("docs-c-tooltip__text-definition-trigger", className)}
            {...rest}
        >
            <Comp ref={forwardedRef}>{_children}</Comp>
        </TooltipPrimitive.Trigger>
    );
});

TooltipTextDefinitionTrigger.displayName = "TooltipTextDefinitionTrigger";

export const TooltipTextDefinitionContent = React.forwardRef<
    React.ElementRef<typeof TooltipContent>,
    React.ComponentProps<typeof TooltipContent>
>((props, forwardedRef) => {
    const { className, children, ...rest } = props;

    return (
        <TooltipContent
            ref={forwardedRef}
            className={cx("docs-c-tooltip__text-definition-content", className)}
            {...rest}
        >
            {children}
        </TooltipContent>
    );
});

TooltipContent.displayName = "TooltipContent";
