export function insertBetween<T>(element: T, array: Array<T>): Array<T> {
    return array.flatMap((x) => [element, x]).slice(1);
}

export const waitUntilDefined = (
    name: string,
    timeout = 250,
    maxWaitSeconds = 20
) => {
    return new Promise((resolve, reject) => {
        const waitFor = (count = 0) => {
            if (typeof (window as any)?.[name] !== "undefined") {
                resolve((window as any)?.[name]);
            } else if (count * timeout >= maxWaitSeconds * 1000) {
                reject();
            } else {
                setTimeout(() => {
                    waitFor(count + 1);
                }, timeout);
            }
        };

        waitFor();
    });
};
