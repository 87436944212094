import { getAnalytics, initializeAnalytics } from "@singlestore/analytics";
import { GatsbyBrowser } from "gatsby";
import zenscroll from "zenscroll";

import { WrapPageElement } from "./src/components/layout/WrapperElements/WrapPageElement";
import { WrapRootElement } from "./src/components/layout/WrapperElements/WrapRootElement";
import { INTERCOM_APP_ID } from "./src/utils/config";
import { waitUntilDefined } from "./src/utils/general";
import { getWriteKey, page } from "./src/utils/segment";

import "./src/styles/main.scss";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] =
    WrapRootElement;

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] =
    WrapPageElement;

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({
    prevLocation,
    location,
}) => {
    // Our custom segment script includes a page call. So we check if
    // prevLocation exists to avoid calling it twice on pageload, and only call
    // it on subsequent page visits.
    if (prevLocation) {
        const referrer = prevLocation.href;

        // Gatsby calls onRouteUpdate before the window.location object actually
        // updates. To counter this we delay tracking call to give window a chance
        // of being updated.
        setTimeout(() => {
            if (window.location.pathname === location.pathname) {
                // `window` has updated info, proceed with defaults
                page({
                    pathname: window.location.pathname,
                    search: window.location.search,
                    referrer,
                });
            } else {
                // `window` has not been updated yet; use Gatsby data to track
                page({
                    pathname: location.pathname,
                    search: location.search,
                    url: location.href,
                    referrer,
                });
            }
        }, 250);
    }

    if (window.Intercom) {
        window.Intercom("update");
    }
};

export const onInitialClientRender: GatsbyBrowser["onInitialClientRender"] =
    async () => {
        initializeAnalytics({
            name: "docs",
            enableAnalytics: true,
        });

        getAnalytics().load({
            variation: "all",
            segmentKey: getWriteKey(),
            reloadOnChange: true,
        });
        // Sync with --base-layout-header-height
        const baseLayoutHeaderHeight = 90;
        zenscroll.setup(null, baseLayoutHeaderHeight + 10);

        waitUntilDefined("Intercom").then(() => {
            window.Intercom("boot", {
                app_id: INTERCOM_APP_ID,
            });
        });
    };
