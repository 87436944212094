import React from "react";
import {
    SearchModalProvider,
    useSearchModalReducer,
} from "contexts/SearchModalContext";
import { SiteProvider } from "contexts/SiteContext";

import { parseDocumentPath } from "utils/url";
import { getVersionSelectorValueFromPath } from "utils/version-selector";

type ProviderProps = {
    pathname: string;
};

export function PathDependentProviders({
    children,
    pathname,
}: React.PropsWithChildren<ProviderProps>) {
    const pathInfo = parseDocumentPath(pathname);
    const versionSelectValue = getVersionSelectorValueFromPath({ pathInfo });

    const [searchModalState, searchModalDispatch] = useSearchModalReducer({
        versionSelectValue,
    });

    const siteContextValue = {
        pathname,
    };

    const searchContextValue = {
        state: searchModalState,
        dispatch: searchModalDispatch,
    };

    return (
        <SiteProvider value={siteContextValue}>
            <SearchModalProvider value={searchContextValue}>
                {children}
            </SearchModalProvider>
        </SiteProvider>
    );
}
