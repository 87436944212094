import { getAllActivePublications } from "@plugins/gatsby-source-paligo/src/config";
import { PublicationsConfigEntry } from "@plugins/gatsby-source-paligo/src/types";

import { PathObject } from "utils/url";

const publications = getAllActivePublications();

const deafultPublicationConfig = publications.find(
    (config) => config.defaultPublication
) as PublicationsConfigEntry;

type GetValueFromUrlProps = {
    pathInfo: PathObject;
};

export const getVersionSelectorValueFromPath = ({
    pathInfo,
}: GetValueFromUrlProps) => {
    // may be different from info in URL since on default publication landing
    // page there is no slug in the URL
    let currentProduct = pathInfo.product;
    let currentVersion = pathInfo.version;

    // if there is no product slug we must be on the default landing page
    if (!pathInfo.product) {
        currentProduct = deafultPublicationConfig.slug;
    }

    // if there is no version slug we must be on a page belonging to the default
    // publication
    if (!pathInfo.version) {
        currentVersion = `v${deafultPublicationConfig.currentVersion}`;
    }

    return `${currentProduct}_${currentVersion}`;
};
