import { LOCALE_REGEX, PRODUCT_REGEX, VERSION_REGEX } from "./regex";

export const BASE_HOST = "docs.singlestore.com";
export const STAGING_HOST = "docs-v2-preview.docs.singlestore.com";

export const SITE_URL = process.env.SITE_URL || `https://${BASE_HOST}`;
export const STAGING_URL = `https://${STAGING_HOST}`;
export const BIFROST_URL = `https://www.singlestore.com`;
export const BIFROST_SERVER_URL =
    process.env.GATSBY_BIFROST_SERVER_URL || "https://www.singlestore.com";

export const FEEDBACK_ENDPOINT = new URL(
    "/api/v1/docs-feedback/",
    BIFROST_SERVER_URL
);

export type PathObject = {
    product?: string;
    version?: string;
    locale?: string;
    documentPath: Array<string>;
};

export type PageOptions = "product" | "version" | "locale";

// converts a pathname (e.g. /cloud/reference/data-api/) and turns it into a
// PathObject
export const parseDocumentPath = (pathname: string): PathObject => {
    const pathObj: PathObject = {
        documentPath: [],
    };

    const fragmentRegex: Array<{
        key: PageOptions;
        regex: RegExp;
    }> = [
        { key: "product", regex: PRODUCT_REGEX },
        { key: "version", regex: VERSION_REGEX },
        { key: "locale", regex: LOCALE_REGEX },
    ];

    const pathParts = pathname.split("/").filter(Boolean);
    let pathIndex = 0;

    for (const { key, regex } of fragmentRegex) {
        if (pathIndex >= pathParts.length) {
            break;
        }

        const match = pathParts[pathIndex].match(regex);
        if (match) {
            pathObj[key] = match[0];
            pathIndex++;
        }
    }

    pathObj.documentPath = pathParts.slice(pathIndex);

    return pathObj;
};

// converts a PathObject into a pathname
export const formatDocumentPath = (pathInfo: PathObject): string => {
    const pathParts = [
        pathInfo.product,
        pathInfo.version,
        pathInfo.locale,
        ...pathInfo.documentPath,
    ].filter(Boolean);

    if (pathParts.length === 0) return "/";

    return `/${pathParts.join("/")}/`;
};

type GetAbsoluteUrlProps = {
    path: string;
    id?: string;
};

export const getAbsoluteUrl = ({ path, id }: GetAbsoluteUrlProps) => {
    const url = `${SITE_URL}${path}`;

    if (id) {
        return `${url}#${id}`;
    }

    return url;
};
