import { createContext } from "@singlestore/fusion/react-utils/context";

type FeedbackBannerContextType = {
    feedbackModalOpen: boolean;
    setFeedbackModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    feedback: string;
    setFeedback: React.Dispatch<React.SetStateAction<string>>;
};

export const [FeedbackBannerProvider, useFeedbackBanner] =
    createContext<FeedbackBannerContextType>();
