import { createContext } from "@singlestore/fusion/react-utils/context";

export type Sentiment = "positive" | "negative" | "neutral";

export type WasThisHelpfulContextType = {
    popoverOpen: boolean;
    setPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
    sentiment: Nullable<Sentiment>;
    setSentiment: React.Dispatch<React.SetStateAction<Nullable<Sentiment>>>;
    reason: Nullable<string>;
    setReason: React.Dispatch<React.SetStateAction<Nullable<string>>>;
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const [WasThisHelpfulProvider, useWasThisHelpfulContext] =
    createContext<WasThisHelpfulContextType>();
