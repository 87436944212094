import {
    EventProperties,
    getAnalytics,
    PageEventProperties,
} from "@singlestore/analytics";

import { isBot } from "utils/is-bot";

const DOCS_PROD = "docs.singlestore.com";
const DOCS_BETA = "beta.docs.singlestore.com";
const DOCS_PREVIEW = "docs-v2-preview.docs.singlestore.com";
const DOCS_STAGING = "staging.docs.singlestore.com";
const DOCS_DEV = "localhost:8000";

export const getWriteKey = (): string => {
    const KEYS: Record<string, string> = {
        [DOCS_PROD]: "PaiQDnT2sFUptjG756y3xATOoXVOAk2d",
        [DOCS_BETA]: "MI6gqtf5nnQfh95Qc2A1tjwXJSCsJIDu",
        [DOCS_PREVIEW]: "MI6gqtf5nnQfh95Qc2A1tjwXJSCsJIDu",
        [DOCS_STAGING]: "MI6gqtf5nnQfh95Qc2A1tjwXJSCsJIDu",
        [DOCS_DEV]: "MI6gqtf5nnQfh95Qc2A1tjwXJSCsJIDu",
        default: "MI6gqtf5nnQfh95Qc2A1tjwXJSCsJIDu",
    };

    const host = window.location.hostname;
    const port = window.location.port;

    let key = KEYS[host];
    if (host === "localhost") {
        key = KEYS[`${host}:${port}`];
    }

    if (!key) {
        console.error(
            `Unhandled domain: ${host}. Falling back to default segment key.`
        );

        key = KEYS.default;
    }

    return key;
};

export const track = (action: string, properties: EventProperties = {}) => {
    if (isBot()) {
        return;
    }

    return getAnalytics().track(action, properties);
};

export const page = (properties: PageEventProperties) => {
    if (isBot()) {
        return;
    }

    return getAnalytics().page(properties);
};
