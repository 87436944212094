import React from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { boxVariants } from "@singlestore/fusion/components/layout/box";
import { split } from "@singlestore/fusion/utils/object";
import { cva, VariantProps } from "class-variance-authority";

import "./FaIcon.scss";

export type FaIconProps = Omit<
    React.ComponentProps<typeof FontAwesomeIcon>,
    "icon"
> &
    VariantProps<typeof icon> & {
        icon: IconDefinition;
    };

const iconVariants = {
    ...boxVariants,
};

export const iconVariantPropsKeys = Object.keys(iconVariants) as Array<
    keyof typeof iconVariants
>;

// Named `sui` since this will be moved to fusion very soon
export const icon = cva("sui-c-icon", {
    variants: iconVariants,
});

export const FaIcon = React.forwardRef<
    React.ElementRef<typeof FontAwesomeIcon>,
    FaIconProps
>((props, forwardedRef) => {
    const { className, ...rest } = props;
    const [iconVariantProps, fontAwesomeIconProps] = split(
        rest,
        iconVariantPropsKeys
    );

    return (
        <FontAwesomeIcon
            ref={forwardedRef}
            className={icon({
                ...iconVariantProps,
                className,
            })}
            {...fontAwesomeIconProps}
        />
    );
});
