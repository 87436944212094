import React from "react";
import uniqBy from "lodash/uniqBy";
import { ResolvedBreadcrumb } from "@plugins/gatsby-source-paligo/src/types";
import { createContext } from "@singlestore/fusion/react-utils/context";
import { isBrowser } from "@singlestore/fusion/utils/dom";

const LOCAL_STORAGE_ENTRY = "pageHistory";
const MAX_ENTRIES = 20;

type PageHistoryEntry = {
    path: string;
    title?: string;
    breadcrumbs: Array<ResolvedBreadcrumb>;
    product: string;
    version: string;
    accessedAt: Date;
};

type PageHistoryStateType = Array<PageHistoryEntry>;

export type PageHistoryAction =
    | {
          type: "push_history";
          value: PageHistoryEntry;
      }
    | {
          type: "set_history";
          value: Array<PageHistoryEntry>;
      };

export type PageHistoryContextType = {
    state: PageHistoryStateType;
    dispatch: React.Dispatch<PageHistoryAction>;
};

// Returns a sorted and duplicate-free state
const cleanState = (state: PageHistoryStateType): PageHistoryStateType => {
    // Sort by most recent
    const sortedState = state.sort(
        (x, y) => Number(y.accessedAt) - Number(x.accessedAt)
    );
    // Delete duplicates and limit entries
    return uniqBy(sortedState, "path").slice(0, MAX_ENTRIES);
};

// Store page history in local storage (to persist after page is closed)
const storeState = (state: PageHistoryStateType) => {
    window.localStorage.setItem(LOCAL_STORAGE_ENTRY, JSON.stringify(state));
};

// Returns page history stored in local storage
const restoreState = (): PageHistoryStateType => {
    if (!isBrowser) {
        return [];
    }

    const pageHistoryStr = window.localStorage.getItem("pageHistory");
    if (!pageHistoryStr) {
        return [];
    }

    // Re-convert dates from string to Date
    let history = JSON.parse(pageHistoryStr).map((entry: PageHistoryEntry) => {
        return {
            ...entry,
            accessedAt: new Date(entry.accessedAt),
        };
    });
    return cleanState(history);
};

export const reducer = (
    state: PageHistoryStateType,
    action: PageHistoryAction
): PageHistoryStateType => {
    switch (action.type) {
        case "push_history": {
            let newState = state.concat([action.value]);
            return reducer(state, {
                type: "set_history",
                value: newState,
            });
        }

        case "set_history": {
            let newState = cleanState(action.value);
            storeState(newState);
            return newState;
        }

        default:
            return state;
    }
};

export const usePageHistoryReducer = () => {
    // Loads history from localstorage if it exists
    const initialState = restoreState();

    return React.useReducer<
        React.Reducer<PageHistoryStateType, PageHistoryAction>
    >(reducer, initialState);
};

export const [PageHistoryProvider, usePageHistoryContext] =
    createContext<PageHistoryContextType>();
