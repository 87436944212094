import React from "react";
import { createContext } from "@singlestore/fusion/react-utils/context";

import { getRecaptchaToken, RECAPTCHA_ENDPOINT } from "utils/recaptcha";

type RecaptchaContextType = {
    recaptchaScore: Nullable<number>;
    generateRecaptchaScore: (action: string) => Promise<number>;
};

export const [RecaptchaPureProvider, useRecaptcha] =
    createContext<RecaptchaContextType>();

export function RecaptchaProvider({ children }: React.PropsWithChildren) {
    const [recaptchaScore, setRecaptchaScore] =
        React.useState<Nullable<number>>(null);

    const generateRecaptchaScore = async (action: string): Promise<number> => {
        const token = await getRecaptchaToken(action);

        return fetch(RECAPTCHA_ENDPOINT, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                token,
                action,
            }),
        })
            .then((response) => response.json())
            .then(({ data, success, message }) => {
                if (success) {
                    setRecaptchaScore(data.score);
                    return data.score;
                } else {
                    throw Error(message);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const recaptchaContextValue = {
        recaptchaScore,
        generateRecaptchaScore,
    };

    return (
        <RecaptchaPureProvider value={recaptchaContextValue}>
            {children}
        </RecaptchaPureProvider>
    );
}
