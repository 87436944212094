import React from "react";
import { createContext } from "@singlestore/fusion/react-utils/context";

const PLAYGROUND_HOST = "https://playground.labs.singlestore.com";
const PLAYGROUND_DB = "docs";

const constructPlaygroundUrl = (content: string) => {
    const base = `${PLAYGROUND_HOST}/?compact=true&database=${PLAYGROUND_DB}`;
    if (!content) {
        return base;
    }

    return `${base}&code=${encodeURIComponent(content)}`;
};

type TabName = "playground" | "sqrl";
type Tab = Nullable<TabName>;

type FlyoutContextType = {
    tabsOpened: Partial<Record<TabName, boolean>>;
    tab: Tab;
    setTab: React.Dispatch<React.SetStateAction<Tab>>;
    code: string;
    setCode: React.Dispatch<React.SetStateAction<string>>;
    iframeUrl?: Nullable<string>;
};

const [PureFlyoutProvider, useFlyout] = createContext<FlyoutContextType>();

export function FlyoutProvider({ children }: React.PropsWithChildren) {
    const [tabsOpened, setTabsOpened] = React.useState<
        Partial<Record<TabName, boolean>>
    >({});
    const [tab, setTab] = React.useState<Tab>(null);
    const [code, setCode] = React.useState<string>("");
    const iframeUrl = React.useMemo<Nullable<string>>(
        () => constructPlaygroundUrl(code),
        [code]
    );

    React.useEffect(() => {
        if (tab) {
            setTabsOpened((prevTabsOpened) => ({
                ...prevTabsOpened,
                [tab]: true,
            }));
        }
    }, [tab]);

    return (
        <PureFlyoutProvider
            value={{
                tabsOpened,
                tab,
                setTab,
                code,
                setCode,
                iframeUrl,
            }}
        >
            {children}
        </PureFlyoutProvider>
    );
}

export { useFlyout };
