import React from "react";
import { createContext } from "@singlestore/fusion/react-utils/context";
import { usePageHistoryContext } from "contexts/PageHistoryContext";

// after the user visits this many pages
const SHOW_NPS_AFTER_PAGES = 5;

const NPS_STATE_KEY = "nps_survey_state";

type NPSState = "closed" | "completed";

type NPSContextType = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    step: number;
    setStep: React.Dispatch<React.SetStateAction<number>>;
    rating: Nullable<number>;
    setRating: React.Dispatch<React.SetStateAction<Nullable<number>>>;
    feedback: string;
    setFeedback: React.Dispatch<React.SetStateAction<string>>;
    error: string;
    setError: React.Dispatch<React.SetStateAction<string>>;
    setNpsState: (state: NPSState) => void;
};

const [PureNPSProvider, useNPS] = createContext<NPSContextType>();

export function NPSProvider({ children }: React.PropsWithChildren) {
    const [open, setOpen] = React.useState<boolean>(false);
    const [step, setStep] = React.useState<number>(1);
    const [rating, setRating] = React.useState<Nullable<number>>(null);
    const [feedback, setFeedback] = React.useState<string>("");
    const [error, setError] = React.useState<string>("");
    const { state: historyState } = usePageHistoryContext();

    // reset to first step when closed
    React.useEffect(() => {
        if (!open) {
            setStep(0);
        }
    }, [open]);

    // open survey after SHOW_NPS_AFTER_PAGES navigations
    React.useEffect(() => {
        const npsSurveyState = localStorage.getItem(NPS_STATE_KEY);
        if (historyState.length > SHOW_NPS_AFTER_PAGES && !npsSurveyState) {
            setOpen(true);
        }
    }, [historyState]);

    const setNpsState = React.useCallback((state: NPSState) => {
        localStorage.setItem(NPS_STATE_KEY, state);
    }, []);

    return (
        <PureNPSProvider
            value={{
                open,
                setOpen,
                step,
                setStep,
                rating,
                setRating,
                feedback,
                setFeedback,
                error,
                setError,
                setNpsState,
            }}
        >
            {children}
        </PureNPSProvider>
    );
}

export { useNPS };
