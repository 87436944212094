import React from "react";

// use this to check if we are in the browser or on the server (generating
// SSR content). we must do it like this to prevent hydration errors.
export const useHasWindow = (): boolean => {
    const [hasWindow, setHasWindow] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (window !== undefined) {
            setHasWindow(true);
        }
    }, []);

    return hasWindow;
};
