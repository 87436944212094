import React from "react";
import { FlyoutProvider } from "contexts/FlyoutContext";

import { PlaygroundEditorFlyout } from "components/PlaygroundEditor/PlaygroundEditorFlyout";
import { SqrlFlyout } from "components/Sqrl/SqrlFlyout";

type WrapPageElementProps = {
    element: React.ReactElement;
};

// Load the flyouts in WrapPageElements so they persits
// when navigating to different pages

export function WrapPageElement({ element }: WrapPageElementProps) {
    return (
        <FlyoutProvider>
            {element}
            <PlaygroundEditorFlyout />
            <SqrlFlyout />
        </FlyoutProvider>
    );
}
