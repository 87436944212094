import React from "react";
import { Span } from "@singlestore/fusion/components/typography";
import { cva, VariantProps } from "class-variance-authority";

import { Override } from "types/utils";

import "./Button.scss";

export const button = cva("button", {
    variants: {
        variation: {
            primary: "primary",
            secondary: "secondary",
            "ghost-primary": "ghost-primary",
            "ghost-secondary": "ghost-secondary",
            "solid-brand": "solid-brand",
        },
        size: {
            sm: "sm",
            md: "md",
            lg: "lg",
        },
        fullWidth: {
            true: "full-width",
        },
        connectedLeft: {
            true: "connected-left",
        },
        connectedRight: {
            true: "connected-right",
        },
    },
});

type ButtonBodyProps = {
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    connectedLeft?: boolean;
    connectedRight?: boolean;
};

export function ButtonBody({
    children,
    iconLeft,
    iconRight,
}: React.PropsWithChildren<ButtonBodyProps>) {
    return (
        <span className="button-body">
            {iconLeft}
            <Span className="inner-text">{children}</Span>
            {iconRight}
        </span>
    );
}

export type ButtonPropsDefault = React.HTMLProps<HTMLButtonElement>;

type ButtonPropsCustom = ButtonBodyProps & {
    onClick?: () => void;
    type?: "submit" | "reset" | "button"; // have to define this manually for some reason
};

// override default button html element props with custom props
export type ButtonComponentProps = Override<
    ButtonPropsDefault,
    ButtonPropsCustom
>;

// override component props with variant props
export type ButtonProps = Override<
    ButtonComponentProps,
    VariantProps<typeof button>
>;

export const Button = React.forwardRef<
    HTMLButtonElement,
    React.PropsWithChildren<ButtonProps>
>(
    (
        {
            children,
            onClick,
            variation = "primary",
            size = "md",
            iconLeft,
            iconRight,
            fullWidth,
            className,
            connectedLeft,
            connectedRight,
            ...rest
        },
        forwardedRef
    ) => {
        const handleClick = () => {
            onClick?.();
        };

        const classes = button({
            className,
            variation,
            size,
            fullWidth,
            connectedLeft,
            connectedRight,
        });

        return (
            <button
                ref={forwardedRef}
                className={classes}
                onClick={handleClick}
                {...rest}
            >
                <ButtonBody iconLeft={iconLeft} iconRight={iconRight}>
                    {children}
                </ButtonBody>
            </button>
        );
    }
);
