import React from "react";
import { useFlyout } from "contexts/FlyoutContext";

import { Flyout } from "components/Flyout/Flyout";

export function PlaygroundEditorFlyout() {
    const { tabsOpened, tab, setTab, iframeUrl } = useFlyout();

    if (!tabsOpened.playground) {
        return null;
    }

    const handleClose = () => {
        setTab(null);
    };

    let iframe;
    if (iframeUrl) {
        iframe = <iframe src={iframeUrl} className="playground-iframe" />;
    }

    return (
        <Flyout
            open={tab === "playground"}
            title="Query Playground"
            onClose={handleClose}
        >
            {iframe}
        </Flyout>
    );
}
