import React from "react";
import { Flex } from "@singlestore/fusion/components/layout";
import { Spinner } from "@singlestore/fusion/components/spinner";
import { useFlyout } from "contexts/FlyoutContext";
import { useHasWindow } from "hooks/use-has-window";

import { Flyout } from "components/Flyout/Flyout";

const InkeepChatWidget = React.lazy(
    async () => import("components/InkeepChatWidget/InkeepChatWidget")
);

export function SqrlFlyout() {
    const { tabsOpened, tab, setTab } = useFlyout();
    const hasWindow = useHasWindow();

    if (!tabsOpened.sqrl) {
        return null;
    }

    const handleClose = () => {
        setTab(null);
    };

    const fallback = (
        <Flex justifyContent="center" alignItems="center" height="full">
            <Spinner />
        </Flex>
    );

    let chat = fallback;
    if (hasWindow) {
        chat = <InkeepChatWidget />;
    }

    return (
        <Flyout open={tab === "sqrl"} title="SQrL Bot" onClose={handleClose}>
            <React.Suspense fallback={fallback}>{chat}</React.Suspense>
        </Flyout>
    );
}
