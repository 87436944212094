import React from "react";
import { createContext } from "@singlestore/fusion/react-utils/context";

import { HitObject } from "types/types";

export type SearchStatus = "allowed" | "blocked" | "pending";

export type SearchModalStateType = {
    hits: Array<HitObject>;
    searchTerm: string;
    versionSelectValue: string;
    resultFocusIndex: number;
    searchModalOpen: boolean;
    shouldPopulateQueryFromUrl: boolean;
    is404Search: boolean;
    searchStatus: SearchStatus;
    isTyping: boolean;
};

export type SearchModalAction =
    | {
          type: "set_hits";
          value: Array<HitObject>;
      }
    | { type: "set_search_term"; value: string }
    | { type: "set_version_select_value"; value: string }
    | { type: "set_result_focus_index"; value: number }
    | { type: "decrement_result_focus_index" }
    | { type: "increment_result_focus_index" }
    | { type: "set_search_modal_open"; value: boolean }
    | { type: "toggle_search_modal_open" }
    | { type: "set_should_populate_query_from_url"; value: boolean }
    | { type: "set_is_404_search"; value: boolean }
    | { type: "set_search_status"; value: SearchStatus }
    | { type: "set_is_typing"; value: boolean };

export type SearchModalContextType = {
    state: SearchModalStateType;
    dispatch: React.Dispatch<SearchModalAction>;
};

export const constructInitialState = (
    initialState: Partial<SearchModalStateType> = {}
): SearchModalStateType => {
    return {
        hits: [],
        searchTerm: "",
        versionSelectValue: "",
        resultFocusIndex: 0,
        searchModalOpen: false,
        shouldPopulateQueryFromUrl: true,
        is404Search: false,
        searchStatus: "pending",
        isTyping: false,
        ...initialState,
    };
};

export const reducer = (
    state: SearchModalStateType,
    action: SearchModalAction
): SearchModalStateType => {
    switch (action.type) {
        case "set_hits":
            return {
                ...state,
                hits: action.value,
            };

        case "set_search_term":
            return {
                ...state,
                searchTerm: action.value,
            };

        case "set_version_select_value":
            return {
                ...state,
                versionSelectValue: action.value,
            };

        case "set_result_focus_index":
            return {
                ...state,
                resultFocusIndex: action.value,
            };

        case "decrement_result_focus_index": {
            let newIndex = state.resultFocusIndex;

            if (state.resultFocusIndex > 0) {
                newIndex = newIndex - 1;
            }

            return {
                ...state,
                resultFocusIndex: newIndex,
            };
        }

        case "increment_result_focus_index": {
            let newIndex = state.resultFocusIndex;

            if (state.resultFocusIndex < state.hits.length - 1) {
                newIndex = newIndex + 1;
            }

            return {
                ...state,
                resultFocusIndex: newIndex,
            };
        }

        case "set_search_modal_open":
            return {
                ...state,
                searchModalOpen: action.value,
            };

        case "toggle_search_modal_open":
            return {
                ...state,
                searchModalOpen: !state.searchModalOpen,
            };

        case "set_should_populate_query_from_url":
            return {
                ...state,
                shouldPopulateQueryFromUrl: action.value,
            };

        case "set_is_404_search":
            return {
                ...state,
                is404Search: action.value,
            };

        case "set_search_status":
            return {
                ...state,
                searchStatus: action.value,
            };

        case "set_is_typing":
            return {
                ...state,
                isTyping: action.value,
            };

        default:
            return state;
    }
};

export const useSearchModalReducer = (
    _initialState: Partial<SearchModalStateType> = {}
) => {
    const initialState = constructInitialState(_initialState);

    return React.useReducer<
        React.Reducer<SearchModalStateType, SearchModalAction>
    >(reducer, initialState);
};

export const [SearchModalProvider, useSearchModalContext] =
    createContext<SearchModalContextType>();
