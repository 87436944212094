import React from "react";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Paragraph } from "@singlestore/fusion/components/typography";
import { cva } from "class-variance-authority";

import { Button } from "components/Button/Button";
import { FaIcon } from "components/FaIcon/FaIcon";

import "./Flyout.scss";

const flyout = cva("flyout", {
    variants: {
        open: {
            true: "open",
        },
    },
});

type FlyoutProps = {
    open: boolean;
    onClose: () => void;
    title: string;
};

export function Flyout({
    children,
    open,
    onClose,
    title,
}: React.PropsWithChildren<FlyoutProps>) {
    const classes = flyout({ open });

    return (
        <div className={classes}>
            <div className="header-row">
                <Paragraph variant="heading-1">{title}</Paragraph>
                <Button variation="ghost-secondary" size="md" onClick={onClose}>
                    <FaIcon icon={faTimes} />
                </Button>
            </div>

            <div className="body">{children}</div>
        </div>
    );
}
