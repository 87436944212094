import { waitUntilDefined } from "utils/general";
import { BIFROST_SERVER_URL } from "utils/url";

declare const grecaptcha: any;

export const RECAPTCHA_ENDPOINT = new URL(
    "/api/v1/docs-recaptcha/",
    BIFROST_SERVER_URL
);
export const RECAPTCHA_PUBLIC_KEY = "6LdgQcMnAAAAADbvlmByvWmRaieSGp8_PcRtOG2f";

export const getRecaptchaToken = async (action: string) => {
    return waitUntilDefined("grecaptcha", 250, 30)
        .then(
            async () =>
                new Promise((resolve, reject) => {
                    grecaptcha.enterprise.ready(async () => {
                        return grecaptcha.enterprise
                            .execute(RECAPTCHA_PUBLIC_KEY, { action })
                            .then(resolve)
                            .catch(reject);
                    });
                })
        )
        .catch(() => console.log("Recaptcha not defined"));
};
